import { Object3D } from "three";
import create from "zustand";

export enum UIViews {
  EditGarden,
  GeneratingGarden,
  LoadingGarden,
  ViewGarden3d,
  ViewGardenTop,
  ViewGardenDiagram,
}

export const editViews = [UIViews.EditGarden];

export const gardenViews = [
  UIViews.LoadingGarden,
  UIViews.ViewGarden3d,
  UIViews.ViewGardenTop,
  UIViews.ViewGardenDiagram,
];

const gardenViewLabels = {
  [UIViews.ViewGarden3d]: "3D",
  [UIViews.ViewGardenTop]: "2D",
  [UIViews.ViewGardenDiagram]: "PLAN",
};

export enum CookieConsentStatus {
  NotSet = 0,
  Accepted = 1,
  Rejected = -1,
}

export const seasons = [
  'Early spring',
  'Mid-spring',
  'Late spring',
  'Early summer',
  'Mid-summer',
  'Late summer',
  'Early autumn',
  'Late-autumn',
  'Winter'
]

type State = {
  view: UIViews;
  cameraTarget: Object3D;
  season: number;
  animateSeasons: boolean;

  cookieConsentStatus: CookieConsentStatus;
  setCookieConsentStatus: (agree: CookieConsentStatus) => void;

  resetTourCounter: number;
  resetTour: () => void;

  tourIsPlaying: boolean;
  setTourIsPlaying: (status: boolean) => void;

  showTutorialPrompt: boolean;
  setShowTutorialPrompt: (status: boolean) => void;

  pollinatorView: boolean;
  togglePollinatorView: (force?: boolean) => void;

  identifyPlants: boolean;
  toogleIdentifyPlants: (force?: boolean) => void;

  audioPlaying: boolean;
  toggleAudioPlaying: (force?: boolean) => void;

  showHelpModal: boolean;
  setShowHelpModal: (boolean) => void;

  showShareModal: boolean;
  setShowShareModal: (boolean) => void;

  agreeTC: boolean;
  setAgreeTC: (boolean) => void;

  openedFormSection: number;
  setOpenedFormSection: (number) => void;

  setCameraTarget: (Object3D) => void;
  changeView: (UIViews) => void;
  setSeason: (season: number) => void;
  toggleAnimateSeason: () => void;
  cycleGardenViews: () => void;
  getGardenViewCycleLabel: () => string;
};

export const useStateStore = create<State>((set, get) => ({
  view: UIViews.EditGarden,
  cameraTarget: null,
  season: 3,
  animateSeasons: false,
  cookieConsentStatus: CookieConsentStatus.NotSet,
  setCookieConsentStatus: (status) => {
    if (status === CookieConsentStatus.Accepted) {
      gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }
    set(() => ({
      cookieConsentStatus: status,
    }));
  },
  resetTourCounter: 0,
  resetTour: () =>
    set((state) => ({ resetTourCounter: state.resetTourCounter + 1 })),
  tourIsPlaying: false,
  setTourIsPlaying: (status) => set((state) => ({ tourIsPlaying: status })),

  showTutorialPrompt: true,
  setShowTutorialPrompt: (status) =>
    set((state) => ({ showTutorialPrompt: status })),

  pollinatorView: false,
  togglePollinatorView: (force: boolean = null) =>
    set((state) => ({
      pollinatorView: force !== null ? force : !state.pollinatorView,
    })),
  identifyPlants: false,
  toogleIdentifyPlants: (force: boolean = null) =>
    set((state) => ({
      identifyPlants: force !== null ? force : !state.identifyPlants,
    })),
  audioPlaying: null,
  toggleAudioPlaying: (force: boolean = null) =>
    set((state) => ({
      audioPlaying: force !== null ? force : !state.audioPlaying,
    })),
  showHelpModal: false,
  setShowHelpModal: (show: boolean) =>
    set((state) => ({ showHelpModal: show })),
  showShareModal: false,
  setShowShareModal: (show: boolean) =>
    set((state) => ({ showShareModal: show })),

  agreeTC: false,
  setAgreeTC: (agree: boolean) => set(() => ({ agreeTC: agree })),

  openedFormSection: 0,
  setOpenedFormSection: (openedFormSection: number) =>
    set((state) => ({ openedFormSection })),

  setCameraTarget: (cameraTarget: Object3D) =>
    set((state) => ({ cameraTarget })),
  changeView: (view: UIViews) => set((state) => ({ view })),
  setSeason: (season: number) => set((state) => ({ season })),
  toggleAnimateSeason: () => {
    set((state) => ({ animateSeasons: !state.animateSeasons }));
  },
  cycleGardenViews: () =>
    set((state) => {
      const viewIdx = gardenViews.indexOf(state.view);
      if (viewIdx === -1) return; // not a garden view
      const nextView = gardenViews[(viewIdx + 1) % gardenViews.length];
      return { view: nextView };
    }),
  getGardenViewCycleLabel: () => {
    return gardenViewLabels[get().view];
  },
}));

let animateIntervalID;
const animateSeasonSpeed = 1000;
const updateSeasonsOnPlay = useStateStore.subscribe(
  (animateSeasons) => {
    if (animateSeasons) {
      animateIntervalID = setInterval(() => {
        useStateStore.setState((state) => {
          const newSeason = (state.season + 1) % seasons.length;
          return { season: newSeason };
        });
      }, animateSeasonSpeed);
    } else {
      clearInterval(animateIntervalID);
    }
  },
  (state) => state.animateSeasons
);

module.exports = {
  locales: ["en","de"],
  defaultLocale: "en",
  pages: {
    "*": [
      "common",
      "modals",
      "errors",
      "plantingInstructions",
      "editgarden",
      "regions",
      "tutorial",
      "urls",
    ],
  },
};

import { Button } from "components/UI/Button";
import Link from "next/link";
import React, { useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { CookieConsentStatus, useStateStore } from "stores/stateStore";
import { mq } from "styles/styles";

export const CookieConsent = () => {
  const { t } = useTranslation("common");
  const status = useStateStore((state) => state.cookieConsentStatus);
  const setStatus = useStateStore((state) => state.setCookieConsentStatus);

  useEffect(() => {
    if (window.document.cookie.indexOf("_ga=") !== -1) {
      setStatus(CookieConsentStatus.Accepted);
    }
  }, []);

  if (status !== CookieConsentStatus.NotSet) return null;
  return (
    <div className="CookieConsent">
      <div className="disclaimer">
        <Trans
          i18nKey="common:cookie-disclaimer"
          components={[<Link href={t`urls:/about/legal#cookie-policy`} />]}
        />
      </div>
      <div className="buttons">
        <Button
          style="default secondary"
          onClick={() => setStatus(CookieConsentStatus.Rejected)}
        >
          {t`reject`}
        </Button>
        <Button onClick={() => setStatus(CookieConsentStatus.Accepted)}>
          {t`accept`}
        </Button>
      </div>
      <style jsx>{`
        .CookieConsent {
          position: fixed;
          bottom: 0;
          left: 0;
          background-color: white;
          padding: var(--spacing);
          width: 100%;
          z-index: 1000;
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
        }
        .CookieConsent :global(a) {
          text-decoration: underline;
        }
        .disclaimer {
          margin-bottom: var(--spacing);
        }
        .buttons {
          display: flex;
          gap: calc(var(--spacing) / 2);
        }

        @media ${mq.mediumUp} {
          .CookieConsent {
            bottom: var(--spacing);
            left: var(--spacing);
            max-width: 35rem;
          }
        }
      `}</style>
    </div>
  );
};

import css from "styled-jsx/css";

export const colors = {
  blue: "#97D0F3",
  lightBlue: "#D7EEF8",
  purple: "#A59DCD",
  darkPurple: "#4B4797",
  black: "#000000",
  yellow: "#F3E600",
  lightYellow: "#FFF3C8",
  grey: "hsl(0,0%,80%)",
  grey2: "hsl(0,0%,70%)",
  grey3: "hsl(0,0%,50%)",
  white: "#FFFFFF",
};

// export const spacing = '2rem'; // in rem

export const soilTextures = {
  none:  "/soil/white-2x2.png",
  chalk: "/soil/chalk.png",
  loam:  "/soil/loam.png",
  clay:  "/soil/clay.png",
  sand:  "/soil/sand.png",
}

export const soilPhColors = {
  none: {h: 0, s: 0, l: 94},
  neutral: {h: 60, s: 100, l: 50},
  acid: {h: 298, s: 81, l: 63},
  alkaline: {h: 165, s: 100, l: 40},
};

export const hslToString = (hsl) => {
  return `hsl(${hsl.h},${hsl.s}%,${hsl.l}%)`;
}

export const breakPoints = {
  mediumUp: 800,
  largeUp: 1140,
  extralargeUp: 1280
};

export const mq = {
  mediumUp: `screen and (min-width: ${breakPoints.mediumUp}px)`,
  largeUp: `screen and (min-width: ${breakPoints.largeUp}px)`,
  extralargeUp: `screen and (min-width: ${breakPoints.extralargeUp}px)`,
};

//
export const cssVars = css.global`
  :root {
    --spacing: 1.5rem;
    --spacing-2x: 3rem;
    --spacing-3x: 4.5rem;
    --spacing-4x: 6rem;

    //colors
    --blue: hsl(203, 96%, 79%);
    --blue-light: hsl(202, 73%, 91%);
    --yellow: hsl(59, 100%, 61%);
    --yellow-light: hsl(46, 100%, 90%);
    --purple: hsl(259, 96%, 56%);
    --purple-light: hsl(245, 74%, 79%);
    --black: hsl(0, 0%, 0%);
    --white: hsl(0, 0%, 100%);
    --grey: hsl(0, 0%, 50%);
    --grey-light: hsl(0, 0%, 70%);
    --grey-lighter: hsl(0, 0%, 90%);
    --white-transparent: hsla(0, 0%, 100%, 0.5); // used for modal overlay
    --black-transparent: hsla(0, 0%, 0%, 0.5); // used for modal overlay

    //typography
    --font-heading-1: bold 2.5rem/1.2 "Metrik", Arial, sans-serif;
    --font-heading-2: bold 1.8rem/1.2 "Metrik", Arial, sans-serif;
    --font-heading-3: bold 1.8rem/1.2 "Metrik", Arial, sans-serif;
    --font-heading-4: bold 1.5rem/1.2 "Metrik", Arial, sans-serif;

    --font-paragraph: normal 2.25rem/1.2 "Neuton", Times, serif;
    --font-paragraph-small: normal 1.8rem/1.2 "Neuton", Times, serif;

    --font-caption: normal 1.4rem/1.2 "Metrik", Arial, sans-serif;
    --font-menu-side: bold 2rem/1.5 "Metrik", Arial, sans-serif;

    //buttons
    --font-button: bold 1.2rem/1.2 "Metrik", Arial, sans-serif;
    --font-button-small: bold 1.2rem/1.2 "Metrik", Arial, sans-serif;
    --font-button-circle: bold 1rem/1.2 "Metrik", Arial, sans-serif;
  }

  @media ${mq.mediumUp} {
    :root {
      --spacing: 2rem;
    }
  }
`;

// Global styles
export const global = css.global`
  // @import url("//hello.myfonts.net/count/3d98ae");

  @font-face {
    font-family: "Metrik";
    src: url("/webFonts/MetrikItalic/font.woff2") format("woff2"),
      url("/webFonts/MetrikItalic/font.woff") format("woff");
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: "Metrik";
    src: url("/webFonts/MetrikRegular/font.woff2") format("woff2"),
      url("/webFonts/MetrikRegular/font.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Metrik";
    src: url("/webFonts/MetrikBold/font.woff2") format("woff2"),
      url("/webFonts/MetrikBold/font.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Neuton";
    src: url("/webFonts/NeutonItalic/font.woff2") format("woff2"),
      url("/webFonts/NeutonItalic/font.woff") format("woff");
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: "Neuton";
    src: url("/webFonts/NeutonRegular/font.woff2") format("woff2"),
      url("/webFonts/NeutonRegular/font.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Neuton";
    src: url("/webFonts/NeutonBold/font.woff2") format("woff2"),
      url("/webFonts/NeutonBold/font.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }

  html {
    font-size: 62.5%; // 10px
  }

  body {
    font-size: 1.6rem;
    touch-action: none;
    overscroll-behavior: none;

    height: 100%;
    min-height: 100vh;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: "Metrik", Arial, sans-serif;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
`;

// Scoped styles
export const button = css`button {
  display: block;
  border: none;
  width: 100%;
  padding: 1em;
}`;
import React, { ReactNode, useEffect, useState } from 'react'
import Floater from "react-floater";
import { TooltipRenderProps } from 'react-joyride';

interface Props {
  content: ReactNode;
  children: React.ReactNode;
}

export const FloaterCustomStyles = {
  floater: {
    filter: "none",
  },
  container: {
    backgroundColor: "var(--blue-light)",
    minWidth: 0,
    minHeight: 0,
    padding: "1.5rem",
  },
  arrow: {
    color: "var(--blue-light)",
    length: 10,
    margin: 8,
    spread: 12,
  },
  content: {
    fontSize: 16,
    color: "var(--black)",
  },
};

const isTouch =
  typeof window !== "undefined" &&
  ("ontouchstart" in window || navigator.maxTouchPoints > 0);

export const StyledFloater = (props: Props) => {
  const [open, setOpen] = useState(false);
  
  const mouseEnter = (e) => {
    setOpen(true);
  }
  const mouseLeave = (e) => {
    setOpen(false);
  }

  useEffect(() => {
    if (!isTouch) return;
    if (!open) return;
    const hideDelay = setTimeout(() => {
      setOpen(false);
    }, 3000);
    return () => {
      clearTimeout(hideDelay);
    }
  }, [open])
 
  return (
    <Floater
      {...props}
      content={props.content}
      disableAnimation={true}
      eventDelay={0}
      styles={FloaterCustomStyles}
      open={open}
    >
      <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onPointerDown={mouseEnter}>{props.children}</div>
    </Floater>
  );
}


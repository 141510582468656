
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { DefaultSeo } from "next-seo";
import { cssVars, global } from "styles/styles";
import { CookieConsent } from "components/CookieConsent/CookieConsent";
import useTranslation from "next-translate/useTranslation";

const GardenCanvas = dynamic(
  () => import("components/Garden/GardenCanvas/GardenCanvas")
);

function MyApp({ Component, pageProps }) {
  const { pathname } = useRouter();

  // TODO use translation and update openGraph for locale
  const { t } = useTranslation("common");
  const locale = t("locale");

  return (
    <>
      <DefaultSeo 
        title="Pollinator Pathmaker"
        description="Design and plant gardens for endangered bees, butterflies and other pollinators using the algorithmic tool - an artwork by Alexandra Daisy Ginsberg."
        openGraph={{
          url: "https://pollinator.art",
          title: "Pollinator Pathmaker",
          description:
            "Design and plant gardens for endangered bees, butterflies and other pollinators using the algorithmic tool - an artwork by Alexandra Daisy Ginsberg.",
          images: [
            {
              url: "https://pollinator.art/pollinator-pathmaker.jpg",
              width: 800,
              height: 480,
              alt: "Pollinator Pathmaker",
              type: "image/jpeg",
            },
          ],
          site_name: "Pollinator Pathmaker",
        }}
        twitter={{
          handle: "@Pollinator_Art",
          cardType: "summary_large_image",
        }}
      />
      {!pathname.includes("/admin") && <GardenCanvas />}
      <CookieConsent />
      <Component {...pageProps} />
      <style jsx global>
        {cssVars}
      </style>
      <style jsx global>
        {global}
      </style>
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  
import { MouseEventHandler } from "react";
import { StyledFloater } from "./StyledFloater";
import { mq } from "styles/styles";
interface Props {
  children?: React.ReactNode;
  style?:
    | "icon"
    | "default"
    | "default secondary"
    | "small"
    | "small pager"
    | "large"
    | "link"
    | "toggle icon"
    | "close"
    | "close fade-in-close"
    | "close fixed-close"
    | "heading"
    | "outline"
    | "purple"
    | "default purple";
  className?: string;
  toolTipText?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
}

export const Button = ({
  onClick,
  children,
  style = "default",
  className = "",
  toolTipText,
  active = true,
}: Props) => {
  const button = (
    <button
      className={`${className} Button ${style} ${
        active ? "active" : "inactive"
      }`}
      onClick={onClick}
    >
      {children}
      <style jsx>{`
        .Button {
          cursor: pointer;
          display: block;
          border: none;
          font: var(--font-button);
        }

        // default
        .Button.default {
          padding: 0 1.2em;
          min-height: 5rem;
          background-color: var(--black);
          text-transform: uppercase;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .Button.default :global(svg) {
          fill: var(--white);
          width: auto;
          height: 100%;
          margin-left: -1.2em;
        }
        .Button.default:hover {
          background-color: hsl(0, 0%, 10%);
        }

        .Button.default.secondary {
          background-color: var(--grey-lighter);
          color: var(--black);
        }

        // outline
        .Button.outline {
          padding: 0 1.2em;
          min-height: 5rem;
          background-color: var(--white);
          border: 1px solid var(--black);
          border-radius: 0;
          font-size: 1.1rem;
          font-weight: bold;
          text-transform: uppercase;
          color: var(--black);
        }
        .Button.outline:hover {
          background-color: var(--black);
          color: var(--white);
        }

        // icon
        .Button.icon {
          position: relative;
          width: 4rem;
          height: 4rem;
          min-width: 4rem;
          background-color: transparent;
          padding: 0;
          font-size: 1.1rem;
          text-transform: uppercase;
        }

        @media ${mq.mediumUp} {
          .Button.icon {
            width: 5rem;
            height: 5rem;
          }
        }

        .Button.icon :global(svg) {
          width: 100%;
          height: 100%;
        }
        @media (hover: hover) and (pointer: fine) {
          .Button.icon:hover :global(svg) {
            fill: var(--purple);
          }
          .Button.icon:hover :global(span) {
            color: var(--purple);
          }
          .Button.toggle.active:hover :global(svg) {
            fill: var(--black);
          }
        }

        // active (toggle) icon
        .Button.toggle.inactive :global(svg) {
        }
        .Button.toggle.active :global(svg) {
          fill: var(--purple);
        }

        // large
        .Button.large {
        }

        .Button.purple {
          background-color: var(--purple);
          color: var(--white);
        }
        .Button.purple:hover {
          background-color: var(--black);
        }

        // link
        .Button.link {
          background: none !important;
          border: none;
          padding: 0 !important;
          font-style: italic;
          color: var(--grey-light);
          font-size: 1.2rem;
          text-decoration: underline;
          cursor: pointer;
          text-align: left;
        }
        .Button.link:hover {
          color: var(--black);
        }

        // heading
        .Button.heading {
          display: inline-block;
          padding: 0 1rem;
          background: none;
          border: none;
          cursor: pointer;
          color: var(--black);
          font-size: 1.25rem;
          font-weight: bold;
          text-decoration: none;
          text-transform: uppercase;
        }

        .Button.heading:hover {
          color: var(--purple);
        }
        @media ${mq.mediumUp} {
          .Button.heading {
            font-size: 1.75rem;
          }
        }

        // close
        .Button.close {
          display: inline-block;
          padding: 0;
          border: none;
          background-color: transparent;
          transform: scale(0.5);
          transform-origin: top right;
        }
        .fade-in-close {
          animation-name: fade-in-close;
          animation-duration: 4s;
        }
        @keyframes fade-in-close {
          0% {
            opacity: 0;
          }
          75% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        // close fixed
        .Button.fixed-close {
          position: fixed;
          right: 0;
          z-index: 11;
          padding: 4rem;
        }
        @media screen and (min-width: 860px) {
          .Button.fixed-close {
            right: calc((100vw - 86rem) / 2);
          }
        }
        .Button.fixed-close:hover :global(svg) {
          fill: var(--purple);
        }

        // small
        .Button.small {
          min-width: 2.8rem;
          height: 2.8rem;
          padding: 0.3em 0.6em;
          font: var(--font-button-small);
          background-color: var(--black);
          color: var(--white);
          text-transform: uppercase;
        }
        .Button.small.pager {
          color: var(--grey);
          border: 1px solid var(--grey);
          background-color: var(--white);
          text-transform: uppercase;
        }
        .Button.small.pager:hover {
          color: var(--black);
          border-color: var(--black);
        }
        .Button.small.pager.active {
          color: var(--white);
          border-color: var(--black);
          background-color: var(--black);
        }
      `}</style>
    </button>
  );
  return toolTipText ? (
    <StyledFloater content={toolTipText}>
      {button}
    </StyledFloater>
  ) : (
    button
  );
};
